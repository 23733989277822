export default async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const options = { timeout: TIMEOUT_IN_MS, ...init }
  const res = await fetch(input, options)

  if (!res.ok) {
    const error: any = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json()
}

const TIMEOUT_IN_MS = 25 * 1000
