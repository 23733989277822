import { useWeb3Context } from 'blockchain/Web3Context'
import Layout from 'components/layout'
import Socials from 'components/socials'
import * as ga from 'helpers/ga'
import { useWindowDimensions } from 'hooks/useWindowDimensions'
import Head from 'next/head'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { formatNum } from 'utils'
import { fetcher } from 'web2'
import Image from 'next/image'

const HoloShapes = () => {
  const { width } = useWindowDimensions()
  const resize = width < 600 ? '30%' : width < 1400 ? '50%' : `100%`
  return (
    <div className="shapesContainer">
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '392px',
        }}
      >
        <div style={{ display: 'block', width: resize }}>
          <Image
            src="/images/hologram-bottom-left.svg"
            width="392px"
            height="625px"
            layout="responsive"
            priority
          />
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          bottom: 0,
          right: 0,
          width: '386px',
        }}
      >
        <div style={{ display: 'block', width: resize, marginLeft: 'auto' }}>
          <Image
            src="/images/hologram-bottom-right.svg"
            width="386px"
            height="445px"
            layout="responsive"
            priority
          />
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          top: '4rem',
          right: 0,
          width: '519px',
        }}
      >
        <div style={{ display: 'block', width: resize, marginLeft: 'auto' }}>
          <Image
            src="/images/hologram-top-right.svg"
            height="162px"
            width="519px"
            layout="responsive"
            priority
          />
        </div>
      </div>
    </div>
  )
}

const NUM_WRAPPED_URL = `${process.env.NEXT_PUBLIC_LAMBDA_URL}/num_wrapped`
const NumGeneratedText: React.FC = () => {
  const { data } = useSWR<{ numWrapped: number }>(NUM_WRAPPED_URL, fetcher)

  return (
    <div className="font-menlo text-body1 pt-4">
      {formatNum(data?.numWrapped ?? 0, 0)} generated
    </div>
  )
}

const DesktopContent: React.FC = () => {
  const { isConnected, connectWallet } = useWeb3Context()
  const { width } = useWindowDimensions()
  const isMobile = width < 500
  const router = useRouter()
  const openWrapped = () => router.push('/wrapped')

  return (
    <div className="flex flex-col gap-6 pb-20 sm:pb-0 md:gap-10 max-w-xs md:max-w-[30rem] items-center justify-center h-full m-auto">
      <div className="text-body1 font-inter font-medium">#CRYPTOWRAPPED</div>
      <div className="text-subheading text-[48px] md:text-title  text-blue font-serif italic ">
        Crypto Wrapped 2021 is ready.
      </div>
      <div className="text-body2 md:text-body1 font-inter md:tracking-normal">
        Get your{' '}
        <a
          onClick={() => ga.event({ action: 'Opened hashtag', params: {} })}
          className="text-blue "
          href="https://twitter.com/cryptowrapped"
          target="_blank"
          rel="noopener noreferrer"
        >
          #CryptoWrapped
        </a>{' '}
        and share your 2021 on-chain activity. Public mint available for everyone.
      </div>
      <div className="flex flex-col gap-6 items-center">
        <div>
          <button
            className="bg-blue font-body1 font-menlo tracking-tigher p-4 rounded-[4px] 
       shadow-lg leading-tight md:leading-snug text-white font-bold cursor-pointer"
            onClick={isConnected ? openWrapped : connectWallet}
          >
            {isConnected ? 'Open Wrapped' : 'Connect Wallet'} 🎁
          </button>
          <NumGeneratedText />
        </div>
        <div className={`md:absolute md:bottom-9 md:left-[50%] md:translate-x-[-50%] text-blue`}>
          <Socials />
        </div>
      </div>
    </div>
  )
}

const LandingPage = () => {
  return (
    <div className="flex items-start top-0 left-0 h-screen w-full overflow-y-hidden fixed">
      <Layout subTheme="white" footerColor="text-blue">
        <Head>
          <title>Crypto Wrapped 2021 🎁</title>
        </Head>

        <HoloShapes />

        <div className="m-w-[590px] text-center h-full">
          <DesktopContent />
        </div>
      </Layout>
    </div>
  )
}

export default LandingPage
